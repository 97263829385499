@charset "utf-8";

// Google Font
@import url("https://fonts.googleapis.com/css?family=Nunito:400,700|Open+Sans:400,700");

// Brand colors
$blue: #60bbea;
$beige-lighter: #eff0eb;
$background-color: #e6e147;

// Update Bulma's global variables
$family-sans-serif: "Nunito", sans-serif;
$family-secondary: "Open Sans", sans-serif;
$primary: $blue;
$title-color: #000;
$widescreen-enabled: false;
$fullhd-enabled: false;
$navbar-background-color: $background-color;
// active navbar styles
$navbar-item-active-color: #fff;
$navbar-item-active-background-color: $blue;
// hover navbar styles
$navbar-item-hover-color: #fff;
$navbar-item-hover-background-color: $blue;

@import "bulma/bulma.sass";

.hero.is-fullheight-with-navbar {
  min-height: calc(100vh - 3.25rem - 2rem);
}

.is-background {
  background-color: $background-color;
}

.chat-bubble {
  position: relative;
  background-color: $white;
  padding: 3rem;
  border-radius: 2rem;
  width: 20rem;
  margin-left: 16rem;

  &:after {
    content: "";
    position: absolute;
    bottom: 15%;
    left: 0%;
    width: 0;
    height: 0;
    border: 60px solid transparent;
    border-top-color: $white;
    border-bottom: 0;
    border-left: 0;
    margin-left: 0px;
    margin-bottom: -60px;
  }
}

.character {
  margin-right: 16rem;
  margin-top: -5rem;

  .character-component {
    animation-name: character-bounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }

  .character-shadow {
    background-color: $grey-light;
    height: 1.5rem;
    width: 100%;
    border-radius: 50%;
    margin: 0 auto;
    animation-name: shadow-bounce;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease;
  }
}

.navbar-menu {
  .navbar-start,
  .navbar-end {
    background-color: $navbar-background-color;
  }

  &.is-overlay {
    margin-top: $navbar-height;
  }

  .navbar-item {
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media screen and (max-width: $desktop) {
  .chat-bubble {
    width: 80%;
    margin-left: 0;

    &:after {
      left: 50%;
    }
  }

  .character {
    margin: 0;
    margin-top: 3rem;
  }
}

// Animations
@keyframes character-bounce {
  0% {
    transform: translateY(-0.5rem);
  }
  50% {
    transform: translateY(0.5rem);
  }
  100% {
    transform: translateY(-0.5rem);
  }
}

@keyframes shadow-bounce {
  0% {
    width: 90%;
    filter: blur(5px);
  }
  50% {
    width: 100%;
    filter: blur(3px);
  }
  100% {
    width: 90%;
    filter: blur(5px);
  }
}
